import React from "react";
import Button from "../Button";

const BookingSection = ({ toggleFunc }) => {
  return (
    <section className="w-full h-fit  py-[54px] lg:py-[60px] background_gradieant_color">
      <div className="w-[90%] lg:w-4/5 mx-auto flex gap-4 lg:gap-10 xl:gap-20 flex-col justify-center lg:justify-between lg:flex-row lg:items-center h-full">
        <div className=" w-full lg:w-[52%]  ">
          <h2 className="text-4xl leading-[44px] text-white fontFamily2 font-bold mb-4 ">
            Find Your Balance
          </h2>
          <p className="font-normal deskfontFamily1 text-base lg:text-xl leading-[28px] lg:leading-[30px ] text-gray_50">
            Prioritizing self-care is essential for establishing balance in your life. Take charge of your health today by reaching out to us for a personalized therapeutic plan designed to rejuvenate and restore your body.
          </p>
        </div>
        <div className="flex w-full lg:w-[48%] flex-col lg:flex-row justify-start lg:justify-end gap-4">
          <a href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/services?lid=429303&eid=1256270&oiid=sv%3A15756249&pId=400656">
          <Button
            customClass="uppercase whitespace-nowrap text-base font-semibold text-white fontFamily2 w-[243px] tracking-[0.03em] rounded-sm border-white border-[1px] h-12 transition hover:bg-[#ffffff4a]"
            texts="Free Consultation"
          />
          </a>
          <a
            href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/all-offer?pId=400656"
          >
            <Button
              customClass="uppercase  whitespace-nowrap text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[165px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
              texts="Book Now"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default BookingSection;
