import React from "react";
import BookingSection from "../components/BookingSection";
import Layout from "../components/Layout";
import Reviews from "../components/ReviewsPage";
import Seo from "../components/SEO";

const META_DATA = {
  title: "Testimonials | Washington DC | HighKey Balance",
  description: `HighKey Balance wellness experts specialize in personalized wellness and recovery treatment for Washington, D.C., athletes, and others who train and play hard.`,
};

const Testimonials = () => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <Reviews />
      <BookingSection />
    </Layout>
  );
};

export default Testimonials;
