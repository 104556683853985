export const testimonials = [
  {
    text: "Serki and her team are pros. Best advice for those looking for treatment: when both ballerinas and football players keep coming back for more, you know it's good. Highly recommend the cryo/sauna/boots combo. You'll feel like a new person.",
    author: "Sarah Steele",
    company: "The Washington Ballet",
  },
  {
    text: "Great recovery day options for anyone active! I did cupping for the first time, and it relieved so much tension I had in my upper back. The woman there has magic hands, which I was told about after my treatment. She gives a spectacular athletic massage with cupping, tension work, and a massage gun. Cannot wait to go there for this massage. Been dreaming of it since I've been told of it.",
    author: "Alex C",
    company: "Google",
  },
  {
    text: "5 stars across the board. I got a wonderful massage that allowed my hips to feel at ease. I didn’t have any aches after leaving. The space at HighKey Balance is very nice and welcoming.",
    author: "Alexa H",
    company: "Google",
  },
  {
    text: "HighKey’s background is quite impressive & holistic, and not to mention the professional fees are quite reasonable. The studio space is warm, peaceful & very relaxing. They provide exceptional customer service and I’m pleased to say they have a loyal customer. Thank you, HighKey Balance, for an amazing recovery session every time!",
    author: "Tigi T",
    company: "Google",
  },
  {
    text: "I can’t say enough good things about HighKey Balance. Serki and her team are the absolute best! I was first introduced to Serki about a year ago, and after my first session, I was hooked. I have always been active but was never good at taking time to recover and care for my body. Serki has taught me the importance of recovery and taking care of my body outside of the gym. If you haven’t already, you need to check HighKey Balance out!",
    author: "Caroline Campisi",
    company: "Google",
  },
  {
    text: "I love HighKey Balance. I always leave feeling relaxed and refreshed. This is definitely the place to go for your recovery needs!",
    author: "Lauren P",
    company: "Google",
  },
  {
    text: "Absolutely wonderful!!  I highly recommend Highkey balance. Excellent service and friendly staff. Loved my experience and will definitely come back again!",
    author: "Yonas F",
    company: "Google",
  },
  {
    text: "This place and staff are amazing, knowledgeable, and personable! I enjoy my visits and the exercises/stretches they recommend I do at home until my next session.",
    author: "Brandi E",
    company: "Facebook",
  },
];
