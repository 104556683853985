import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { testimonials } from "../../constants/testimonials";
import Button from "../Button";

const DisplayCompanyLogo = ({ company }) => {
  if (company === "Google") {
    return (
      <StaticImage
      placeholder="none"
        src="../../static/images/google-logo.png"
        alt="brand-logo"
        className="mb-12"
      />
    );
  } else if (company === "Facebook") {
    return (
      <StaticImage
      placeholder="none"
        src="../../static/images/facebook.png"
        alt="brand-logo"
        className="mb-12"
      />
    );
  } else {
    return <div>{company}</div>;
  }
};

const Reviews = () => {
  const middleIndex = Math.ceil(testimonials.length / 2);
  const firstHalf = [...testimonials].splice(0, middleIndex);
  const secondHalf = [...testimonials].splice(-middleIndex);

  return (
    <section className="lg:mt-20 mt-10 ">
      <div className="w-fit mx-auto mb-[102px] pt-[72px] flex flex-col gap-6 items-center">
        <h1 className="fontFamily2 text-[42px] text-center leading-[51px] font-bold text-gray_900">
          HighKey Tributes
        </h1>
        <p className="text-typography_body text-center font-normal fontFamily1 text-base leading-7">
          Your wellness and recovery are important to us, and it’s reviews like
          these that make us smile.{" "}
        </p>
        <a href="/review-us/">
        <Button
          texts={"Leave a Review"}
          customClass={
            "uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[211px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
          }
          
        />
        </a>
      </div>
      <div className="w-[90%]  flex flex-col lg:flex-row gap-12  lg:w-4/5 mb-[64px] mx-auto">
        <div className="w-full lg:w-2/4 flex flex-col gap-12">
          {firstHalf.map((item) => {
            return (
              <div className="h-fit border-[1px]  border-gray_200 rounded-lg p-8">
                <div className="flex mb-6 justify-between items-center">
                  <div className="flex gap-3 items-center">
                    <p className="text-gray_900 fontFamily1 font-bold text-base ">
                      {item.author}
                    </p>
                  </div>
                  <div>
                    <DisplayCompanyLogo company={item.company} />
                  </div>
                </div>
                <p className="text-base font-normal text-typography_body fontFamily1 leading-7 ">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-full lg:w-2/4 flex flex-col gap-12">
          {secondHalf.map((item) => (
            <div className="h-fit border-[1px] border-gray_200 rounded-lg p-8">
              <div className="flex mb-6 justify-between items-center">
                <div className="flex gap-3 items-center">
                  <p className="text-gray_900 fontFamily1 font-bold text-base ">
                    {item.author}
                  </p>
                </div>
                <div>
                  <DisplayCompanyLogo company={item.company} />
                </div>
              </div>
              <p className="text-base font-normal text-typography_body fontFamily1 leading-7 ">
                {item.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
